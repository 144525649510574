.container {
    width: 1000px;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Nerko One', cursive;
    font-family: 'Ubuntu', sans-serif;
    box-shadow: 0 0 10px 1px rgb(51, 49, 49);
}

p {
    color: #515151;
}

img {
    width: 250px;
    height: 250px;
}

.technical-skills-section {
    margin: 120px 50px;
}

.marks-dots {
    display: flex;
    justify-content: space-between;
}

.dots {
    margin-bottom: 10px;
}

.this {
    margin: 0 0 45px 0;
}

.tech {
    font-size: 18px;
    font-weight: bold;
}

.techskils {
    margin-bottom: 20px;
    font-weight: bold;
}

#awards {
    margin-top: 100px;
    font-weight: bold;
}

.awards {
    margin-top: 100px;
}

.company {
    margin-top: 10px;
    font-weight: bold;
}

.udemy {
    font-weight: bold;
}

.Work-experience {
    margin: 120px 50px;
}

hr {
    border: 2px solid blue;
    height: 5px;
    margin-top: 50px;
    background-color: blue;
}

#junior,
#graphic {
    margin-top: 40px;
}

#top-image {
    width: 500px;
    margin-top: -285px;
    margin-left: -120px;
}

#bottom-image {
    margin-top: -800px;
    margin-left: 917px;
    height: 800px;
    width: 60px;
    position: absolute;
    z-index: 0;
}

#image-shap2 {
    margin-top: -200px;
    margin-left: -50px;
    height: 400px;
    width: 500px;
}

.personal {
    margin-top: 80px;
}